import React from 'react';

const GithubIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 490.67">
      <title>Github Icon</title>
      <path
        d="M256,10.67C114.56,10.67,0,123.31,0,262.23,0,373.4,73.34,467.67,175,500.91c12.8,2.36,17.49-5.42,17.49-12.1,0-6-.21-21.8-.32-42.77C121,461.21,106,412.29,106,412.29c-11.64-29-28.48-36.8-28.48-36.8-23.19-15.6,1.8-15.28,1.8-15.28,25.7,1.75,39.21,25.92,39.21,25.92,22.82,38.47,59.92,27.35,74.56,20.93,2.3-16.28,8.89-27.35,16.21-33.64-56.85-6.29-116.61-27.93-116.61-124.31,0-27.46,9.92-49.9,26.35-67.5-2.88-6.36-11.52-31.94,2.24-66.58,0,0,21.44-6.74,70.4,25.79a245.47,245.47,0,0,1,128,0C368.3,108.29,389.74,115,389.74,115c13.76,34.64,5.12,60.22,2.56,66.58,16.32,17.6,26.24,40,26.24,67.5,0,96.64-59.84,117.91-116.8,124.1,9,7.55,17.28,23,17.28,46.54,0,33.67-.32,60.72-.32,68.89,0,6.59,4.48,14.46,17.6,11.95C438.72,467.56,512,373.23,512,262.23,512,123.31,397.38,10.67,256,10.67Z"
        transform="translate(0 -10.67)"
      />
    </svg>
  );
};

export default GithubIcon;
