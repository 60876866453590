import React from 'react';

const MailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 384">
      <title>Mail Icon</title>
      <path
        d="M10.69,95.16C81,154.67,204.26,259.36,240.5,292c4.87,4.41,10.08,6.65,15.5,6.65s10.61-2.22,15.47-6.61C307.74,259.38,431,154.67,501.31,95.16a10.66,10.66,0,0,0,1.5-14.72A42.35,42.35,0,0,0,469.33,64H42.67A42.35,42.35,0,0,0,9.19,80.44,10.66,10.66,0,0,0,10.69,95.16Z"
        transform="translate(0 -64)"
      />
      <path
        d="M505.81,127.41A10.6,10.6,0,0,0,494.44,129C416.51,195,317.05,279.69,285.76,307.89c-17.56,15.85-41.94,15.85-59.54,0-33.36-30.05-145-125-208.66-178.91A10.67,10.67,0,0,0,0,137.08V405.33A42.72,42.72,0,0,0,42.67,448H469.33A42.72,42.72,0,0,0,512,405.33V137.08A10.66,10.66,0,0,0,505.81,127.41Z"
        transform="translate(0 -64)"
      />
    </svg>
  );
};

export default MailIcon;
